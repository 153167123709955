<template>
    <Factory />
  </template>
  
  <script>
  import Factory from "../../../components/factory_demo_washboard";
  
  export default {
    name: "factory_demo_washboard",
    components: {
      Factory,
    },
  };
  </script>